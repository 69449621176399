import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Key from "./HTML/Key";
import Value from "./HTML/Value";

export interface UpdateConfigurationProps {}
export default function UpdateConfiguration(
  props: UpdateConfigurationProps
) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      {" "}
      <Stack spacing={7}>
        <Key formHook={formHook} />
        <Value formHook={formHook} />
      </Stack>
    </>
  );
}
