import { Modal, Table } from "@chakra-ui/react";
import { AdditionalOption, CrudOptions } from "hooks/crud/interfaces";
import { FiTrash2, FiEdit2 } from "react-icons/fi";
import TableLoader from "../loader/TableLoader";
import TableBody from "./TableBody";
import TableHeader, { TableHeaderProps } from "./TableHeader";

export interface TableProps {
  tableHeaderProps: TableHeaderProps;
  data: any[];
  viewMethod: (id: string) => void;
  updateMethod: (id: string) => void;
  deleteMethod: (id: string) => void;
  crudOptions?: CrudOptions;
  additionalOptions?: AdditionalOption[];
}
export default function GenericTable(props: TableProps) {
  const {
    data,
    tableHeaderProps,
    viewMethod,
    updateMethod,
    deleteMethod,
    crudOptions,
    additionalOptions,
  } = props;
  return (
    <>
      <Table size={"sm"}>
        <TableHeader {...tableHeaderProps} />
        <TableBody
          items={data}
          tableHeaderProps={tableHeaderProps}
          viewMethod={viewMethod}
          updateMethod={updateMethod}
          deleteMethod={deleteMethod}
          crudOptions={crudOptions}
          additionalOptions={additionalOptions}
        />
      </Table>
    </>
  );
}
