import {
  useBreakpointValue,
  HStack,
  VStack,
  InputProps,
} from "@chakra-ui/react";
import GenericDate from "shared/components/date/GenericDate";

export interface ItemDateProps {
  additionalInputProps?: InputProps;
}
export default function ItemDate(props: ItemDateProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;

  return (
    <>
      <GenericDate
        title={"Date Of Invoice"}
        property={"date"}
        additionalInputProps={additionalInputProps}
      />
    </>
  );
}
