import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

export interface DeleteInvoiceProps {}
export default function DeleteInvoice(props: DeleteInvoiceProps) {
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
      
      </Stack>
    </>
  );
}
