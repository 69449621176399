import { CallResponse, IAgentGenericCalls } from "api/interface";
import { AxiosResponse } from "axios";
import {
  InvoiceDTO,
  CreateInvoiceDTO,
  ViewInvoiceDTO,
  UpdateInvoiceDTO,
} from "components/Payroll/Invoice/interfaces";
import ff, { gg, requests } from "../general";
const InvoiceEP = process.env.REACT_APP_API_INVOICE;
const Invoice: IInvoiceCalls = {
  ...gg<InvoiceDTO, CreateInvoiceDTO, ViewInvoiceDTO, UpdateInvoiceDTO>(
    InvoiceEP!
  ),
  EmailInvoice: (id: string) => {
    return requests.post(`${InvoiceEP}/EmailInvoice`, { id });
  },
};
Invoice.Select = () => {
  return requests.get(`${InvoiceEP}/Select`, new URLSearchParams());
};

export interface IInvoiceCalls
  extends IAgentGenericCalls<
    InvoiceDTO,
    CreateInvoiceDTO,
    ViewInvoiceDTO,
    UpdateInvoiceDTO
  > {
  EmailInvoice: (id: string) => Promise<AxiosResponse<CallResponse<any>, any>>;
}

export default Invoice;
