import { CreateToastFnReturn } from "@chakra-ui/react";
import { createContext } from "react";

export interface AppContextProps {
  isLoading: boolean;
  setIsLoading: () => void;
  toast?: CreateToastFnReturn;
}

const AppContext = createContext<AppContextProps>({
  isLoading: true,
  setIsLoading: () => {},
});

export default AppContext;