import { InputProps, Input } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface HourlyWageProps {
  additionalInputProps?: InputProps;
}
export default function HourlyWage(props: HourlyWageProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("hourlyWage", {
              required: `Hourly Wage is required`,
              min: { value: 1, message: "Wage has to be greater than 1" },
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            type={"number"}
            {...additionalInputProps}
          />
        }
        label={`Hourly Wage`}
        placeHolder=""
        errorMessage={errors["hourlyWage"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
