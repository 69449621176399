import { Stack, HStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import AccountNumber from "./HTML/AccountNumber";
import Address from "./HTML/Address";
import Name from "./HTML/Name";
import NIS from "./HTML/NIS";
import BankStack from "./HTML/Stacks/BankStack";
import IDStack from "./HTML/Stacks/IDStack";
import NameStack from "./HTML/Stacks/NameStack";
import TRN from "./HTML/TRN";

export interface ViewEmployeeProps {}
export default function ViewEmployee(props: ViewEmployeeProps) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <NameStack additionalInputProps={{ isReadOnly: true }} />
        {/* <Address
          formHook={formHook}
          additionalInputProps={{ isReadOnly: true }}
        /> */}
        <IDStack additionalInputProps={{ isReadOnly: true }} />
        {/* <BankStack
          additionalInputProps={{ isReadOnly: true }}
          additionalTInputProps={{ isReadOnly: true }}
        /> */}
      </Stack>
    </>
  );
}
