import { InputProps, Input } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface EmployeeNameProps {
    additionalInputProps?: InputProps;
  }
  export default function EmployeeName(props: EmployeeNameProps) {
    const { additionalInputProps } = props;
    const {
      register,
      formState: { errors },
    } = useFormContext();
    return (
      <>
        <GenericFormControl
          input={
            <Input
              {...register("emp", {
                required: `Hourly Wage is required`,
                min: { value: 1, message: "Wage has to be greater than 1" },
              })}
              id="large"
              size="lg"
              placeholder=" "
              data-peer
              {...{isReadOnly:true}}
            />
          }
          label={`Employee Name`}
          placeHolder=""
          errorMessage={errors["emp"]?.message as string | undefined}
          isRequired={true}
        />
      </>
    );
  }
  