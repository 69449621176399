import axios, { AxiosResponse } from "axios";
import { CallResponse, IAgentGenericCalls } from "./interface";

const requestBody = (response: AxiosResponse<CallResponse<any>, any>) =>
  response;
export const requests = {
  get: (url: string, params?: URLSearchParams) => {
    return axios.get(url, { params }).then(requestBody);
  },
  post: (url: string, body: {}) => {
    return axios.post(url, body).then(requestBody);
  },
  put: (url: string, body: {}) => {
    return axios.put(url, body).then(requestBody);
  },
  delete: (url: string) => {
    return axios.delete(url).then(requestBody);
  },
};

const ff = {
  requests,
};

export function gg<List, Create, Read, Update>(call: string) {
  const Calls: IAgentGenericCalls<List, Create, Read, Update> = {
    Create: function (
      data: Create
    ): Promise<AxiosResponse<CallResponse<List[]>, any>> {
      return requests.post(`${call}`, data as any);
    },
    Update: function (
      data: Update
    ): Promise<AxiosResponse<CallResponse<List[]>, any>> {
      return requests.put(`${call}/${(data as any).id}`, data as any);
    },
    Items: function (
      params: URLSearchParams
    ): Promise<AxiosResponse<CallResponse<List[]>, any>> {
      return requests.get(`${call}`, params);
    },
    Item: function (
      id: string
    ): Promise<AxiosResponse<CallResponse<Read>, any>> {
      return requests.get(`${call}/${id}`);
    },
    Delete: function (
      id: string
    ): Promise<AxiosResponse<CallResponse<List[]>, any>> {
      return requests.delete(`${call}/${id}`);
    },
  };
  return Calls;
}

export default ff;
