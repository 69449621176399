import { Fade, Progress } from "@chakra-ui/react";

export interface CrudModalLoaderProps {
  isLoading: boolean;
}
export default function CrudModalLoader(props: CrudModalLoaderProps) {
  const { isLoading } = props;
  return (
    <>
      <Fade in={isLoading}>
        <Progress isIndeterminate />
      </Fade>
    </>
  );
}
