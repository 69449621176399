import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import HourlyWage from "./HTML/HourlyWage";
import Status from "./HTML/Status";
import DateStack from "./Stacks/DateStack";
import SelectStack from "./Stacks/SelectStack";

export interface DeleteContractInstanceProps {}
export default function DeleteContractInstance(
  props: DeleteContractInstanceProps
) {
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <SelectStack
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <DateStack
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <HourlyWage
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <Status/>
      </Stack>
    </>
  );
}
