import axios, { AxiosResponse } from "axios";
import {
  CreateEmployeeDTO,
  EmployeeDTO,
  UpdateEmployeeDTO,
  ViewEmployeeDTO,
} from "components/Payroll/Employee/interfaces";
import Configuration from "./Calls/configCalls";
import ContractBluePrint from "./Calls/contractBluePrintCalls";
import ContractInstance from "./Calls/contractInstanceCalls";
import Employee from "./Calls/employeeCalls";
import HourlyFiling from "./Calls/hourlyFilingCalls";
import Invoice from "./Calls/invoiceCalls";
import Security from "./Calls/securityCalls";
import Service from "./Calls/serviceCalls";
import { CallResponse } from "./interface";
import { IAgentGenericCalls } from "./interface";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 1000));
const apiURL = process.env.REACT_APP_API_URL;
const EmployeeEP = process.env.REACT_APP_API_EMPLOYEE;
axios.defaults.baseURL = apiURL;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) (config.headers! as any).Authorization = `Bearer ${token}`;
  return config;
});

const agent = {
  Employee,
  ContractBluePrint,
  Service,
  ContractInstance,
  HourlyFiling,
  Security,
  Invoice,
  Configuration
};

export default agent;
