import {
  HStack,
  InputProps,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Name from "../Name";

export interface NameStackProps {
  additionalInputProps?: InputProps;
}
export default function NameStack(props: NameStackProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  let g = [
    ["firstName", "First Name"],
    ["middleName", "Middle Name"],
    ["lastName", "Last Name"],
  ];
  if (isDesktop) {
    return (
      <HStack spacing={5}>
        {g.map((f) => (
          <Name
            key={f[0]}
            property={f[0]}
            title={f[1]}           
            additionalInputProps={additionalInputProps}
          />
        ))}
      </HStack>
    );
  }

  return (
    <>
      <VStack spacing={5}>
        {g.map((f) => (
          <Name
            key={f[0]}
            property={f[0]}
            title={f[1]}
            additionalInputProps={additionalInputProps}
          />
        ))}
      </VStack>
    </>
  );
}
