import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import HourlyWage from "./HTML/HourlyWage";

export interface UpdateContractInstanceProps {}
export default function UpdateContractInstance(
  props: UpdateContractInstanceProps
) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <HourlyWage />
      </Stack>
    </>
  );
}
