import agent from "api/agent";
import exp from "constants";
import AuthenticationContext, { IClaim } from "Contexts/AuthenticationContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { NavigateFunction, useNavigate } from "react-router";
import { useToast } from "shared/components/toast/useToast";
import { LoginDTO } from "./interfaces";
export interface ISecurity {
  type: "login" | "register";
}
export interface IAuthenticationResponse {
  token: string;
  expiration: string;
}
const tokenKey = "token";
const expirationKey = "token-expiration";
export default function useSecurity({ type }: ISecurity) {
  const { updateClaims } = useContext(AuthenticationContext);
  const { ErrorToast, SuccessToast } = useToast();
  const navigate = useNavigate();
  const formHook = useForm<LoginDTO>();
  let submit: (data: LoginDTO) => void = async (data: LoginDTO) => {
    await agent.Security.Login(data)
      .then((response) => {
        // toast.success("Success, you will be redirected.");
        SuccessToast({
          description: "Welcome",
        });
        saveToken(response.data.result.token, updateClaims,navigate);
      })
      .catch((error) => {
        ErrorToast({
          description: "UserName or Password is Incorrect. Please try again.",
        });
      });
  };
  let error: (error: any) => void = (error: any) => {
    console.log("EE");
  };
  switch (type) {
    case "login": {
      break;
    }
    case "register": {
      break;
    }
  }
  return {
    formHook,
    submit,
    error,
    logOut,
  };
}

function saveToken(
  response: IAuthenticationResponse,
  updateClaims: any,
  navigate: NavigateFunction
) {
  localStorage.setItem(tokenKey, response.token);
  localStorage.setItem(expirationKey, response.expiration);
  const claims = getClaims();
  updateClaims(claims);
  const isUpdate = claims.find((x) => x.property === "updatePassword")!.value;
  if (isUpdate === "True") {
    navigate("/updatePassword");
  } else {
    navigate("/");
  }
}

export function getClaims(): IClaim[] {
  const token = localStorage.getItem(tokenKey);
  if (!token) {
    return [];
  }
  const expiration = localStorage.getItem(expirationKey)!;
  console.log(expiration);
  if (new Date(expiration) <= new Date()) {
    alert("WSer");
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expirationKey);
    return [];
  }

  const dataToken = JSON.parse(atob(token.split(".")[1]));
  console.log(dataToken);
  const claims: IClaim[] = [];
  Object.keys(dataToken).forEach((key) => {
    if (key === "roles") {
      let roles = JSON.parse(dataToken[key]) as string[];
      claims.push({ property: key, value: roles });
    } else {
      claims.push({ property: key, value: dataToken[key] });
    }
  });
  return claims;
}

export function logOut(updateClaims: any, navigate: NavigateFunction) {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(expirationKey);
  const claims = getClaims();
  navigate("/login");
  updateClaims(claims);
}
