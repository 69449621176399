import { useBreakpointValue, HStack, VStack } from "@chakra-ui/react";
import { FieldValues, FormProvider, useForm, UseFormReturn } from "react-hook-form";
import InvoiceDate from "./HTML/InvoiceDate";
import AmountDescription from "./HTML/Item/AmountDescription";
import AmountTotal from "./HTML/Item/AmountTotal";
import Description from "./HTML/Item/Description";
import ItemDate from "./HTML/Item/ItemDate";
import ManHours from "./HTML/Item/ManHours";
import Name from "./HTML/Item/Name";
import WorkRate from "./HTML/Item/WorkRate";

export interface InvoiceItemProps {
    formHook: UseFormReturn<any, any>
}
export default function InvoiceItem(props: InvoiceItemProps) {
  const {formHook} = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <>
      <FormProvider {...formHook}>
        {isDesktop === true ? (
          <>
            <HStack spacing={7}>
              <ItemDate />
              <Name />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing={7}>
              <ItemDate />
              <Name />
            </VStack>
          </>
        )}

        <Description />
        {isDesktop === true ? (
          <>
            <HStack>
              <AmountDescription />
              <AmountTotal />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing={7}>
              <AmountDescription />
              <AmountTotal />
            </VStack>
          </>
        )}

        {isDesktop === true ? (
          <>
            <HStack spacing={7}>
              <ManHours />
              <WorkRate />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing={7}>
              <ManHours />
              <WorkRate />
            </VStack>
          </>
        )}
      </FormProvider>
    </>
  );
}
