import { Stack, useBreakpointValue } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import ServicePrice from "./HTML/ServicePrice";
import BasicInfoStack from "./HTML/Stacks/BasicInfoStack";
import DateStack from "./HTML/Stacks/DateStack";

export interface CreateServiceProps {}
export default function CreateService(props: CreateServiceProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const {} = props;
  const formHook = useFormContext();

  return (
    <>
      <Stack spacing={7}>
        <BasicInfoStack />
        <ServicePrice />
        <DateStack />
      </Stack>
    </>
  );
}
