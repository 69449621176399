import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import Authorize from "components/Authentication/Authorize";
import ForgetPassword from "components/Authentication/ForgetPassword";
import Login from "components/Authentication/Login";
import UpdatePassword from "components/Authentication/UpdatePassword";
import Configurations from "components/Payroll/Config/Configurations";
import ContractBluePrints from "components/Payroll/ContractBluePrint/ContractBluePrints";
import ContractInstances from "components/Payroll/ContractInstance/ContractInstances";
import HourlyFilings from "components/Payroll/HourFiling/HourFilings";
import Invoices from "components/Payroll/Invoice/Invoices";
import Services from "components/Payroll/Service/Services";
import AuthenticationContext from "Contexts/AuthenticationContext";
import { useContext, useState } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { Route, Routes, useNavigate } from "react-router-dom";
import Employees from "../Payroll/Employee/Employees";
import NavBar from "./Navigation/NavBar";
import SideBar from "./Navigation/SideBar";

export interface MainProps {}
export default function Main(props: MainProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const {} = props;
  const { claims, isAuthenticated } = useContext(AuthenticationContext);
  const isUpdate = claims?.find((x) => x.property === "updatePassword")?.value;
  const navigate = useNavigate();
  if (isUpdate === "True") {
    return (
      <>
        <Routes>
          <Route path="/updatePassword" element={<UpdatePassword />} />
          
          <Route path="*" element={<Authorize element={<></>} />} />
        </Routes>
      </>
    );
  }

  if (!isAuthenticated) {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="*" element={<Authorize element={<></>} />} />
        </Routes>
      </>
    );
  }
  return (
    <>
      <Flex
        as="section"
        direction={{ base: "column", lg: "row" }}
        height="100vh"
        bg="bg-canvas"
        overflowY="auto"
      >
        {isDesktop ? <SideBar /> : <NavBar />}
        <Box bg="bg-accent" pt={{ base: "0", lg: "3" }} flex="1">
          <Box
            bg="bg-canvas"
            borderTopLeftRadius={{ base: "none", lg: "2rem" }}
            height="full"
          >
            <Container py="8" height="full">
              <Routes>
                <Route
                  path="/employees"
                  element={<Authorize element={<Employees />} />}
                />
                <Route
                  path="/contract-blue-prints"
                  element={<ContractBluePrints />}
                />
                <Route path="/services" element={<Services />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route
                  path="/contract-instances"
                  element={<ContractInstances />}
                />
                <Route path="/currentWeekFiling" element={<HourlyFilings />} />
                <Route path="/config" element={<Configurations />} />
              </Routes>
            </Container>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
