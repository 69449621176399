import { CallResponse, IAgentGenericCalls } from "api/interface";
import { AxiosResponse } from "axios";
import {
  HourlyFilingDTO,
  CreateHourlyFilingDTO,
  ViewHourlyFilingDTO,
  UpdateHourlyFilingDTO,
} from "components/Payroll/HourFiling/interfaces";
import ff, { gg, requests } from "../general";
const HourlyFilingEP = process.env.REACT_APP_API_HOURLY_FILING;
const HourlyFiling: IHourlyFilingAgentCalls = {
  ...gg<
    HourlyFilingDTO,
    CreateHourlyFilingDTO,
    ViewHourlyFilingDTO,
    UpdateHourlyFilingDTO
  >(HourlyFilingEP!),
  CurrentFilings: ( params: URLSearchParams) => {
    return requests.get(`${HourlyFilingEP}/current`, params);
  },
  GeneratePaySlips:()=>{
    return requests.post(`${HourlyFilingEP}/GeneratePaySlips`,{});
  },
  EmailCashFlow:()=>{
    return requests.post(`${HourlyFilingEP}/EmailCashFlow`,{});
  },
  EmailHourTracker:()=>{
    return requests.post(`${HourlyFilingEP}/EmailHourTracker`,{});
  },
  GetCurrentFilingDates:()=>{
    return requests.get(`${HourlyFilingEP}/Dates`);
  }
};
HourlyFiling.Select = () => {
  return requests.get(`${HourlyFilingEP}/Select`, new URLSearchParams());
};

export interface IHourlyFilingAgentCalls
  extends IAgentGenericCalls<
    HourlyFilingDTO,
    CreateHourlyFilingDTO,
    ViewHourlyFilingDTO,
    UpdateHourlyFilingDTO
  > {
  CurrentFilings: (
    params: URLSearchParams
  ) => Promise<AxiosResponse<CallResponse<HourlyFilingDTO[]>, any>>;
  GeneratePaySlips: () => Promise<AxiosResponse<CallResponse<any>, any>>;
  EmailCashFlow: () => Promise<AxiosResponse<CallResponse<any>, any>>;
  EmailHourTracker: () => Promise<AxiosResponse<CallResponse<any>, any>>;
  GetCurrentFilingDates: () => Promise<AxiosResponse<CallResponse<string[]>, any>>;
}

export default HourlyFiling;
