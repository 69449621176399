import {
  ContractBluePrintDTO,
  CreateContractBluePrintDTO,
  ViewContractBluePrintDTO,
  UpdateContractBluePrintDTO,
} from "components/Payroll/ContractBluePrint/interfaces";
import ff, { gg, requests } from "../general";
const EP = process.env.REACT_APP_API_CONTRACT_BLUEPRINT;
const ContractBluePrint = gg<
  ContractBluePrintDTO,
  CreateContractBluePrintDTO,
  ViewContractBluePrintDTO,
  UpdateContractBluePrintDTO
>(EP!);
ContractBluePrint.Select = () => {
  return requests.get(`${EP}/Select`, new URLSearchParams());
};
export default ContractBluePrint;
