import {
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import agent from "api/agent";
import UserProfile from "components/Authentication/UserProfile";
import {
  FiShoppingBag,
  FiSun,
  FiMoon,
  FiFilm,
  FiCamera,
  FiMusic,
  FiMic,
  FiLayers,
  FiAirplay,
  FiAperture,
  FiCalendar,
  FiUser,
  FiActivity,
  FiAward,
} from "react-icons/fi";

import ClickableButton from "./ClickableButton";
import GeneratePayrollButton from "./GeneratePayrollButton";
import SideBarButton from "./SideBarButton";

export interface SideBarProps {
  isClose?: React.Dispatch<React.SetStateAction<number>>;
}
export default function SideBar(props: SideBarProps) {
  const { isClose } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <>
      <Flex
        flex="1"
        bg="bg-accent"
        color="on-accent"
        overflowY="auto"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="8" shouldWrapChildren>
            <HStack>
              <UserProfile />
              <Spacer />
              <IconButton
                colorScheme={"blackAlpha"}
                aria-label="color-mode"
                icon={isDark ? <FiSun /> : <FiMoon />}
                isRound={true}
                onClick={toggleColorMode}
              ></IconButton>
            </HStack>
           
            <Stack spacing="1">
              {/* <NavButton label="Home" icon={FiHome} /> */}
              {/* <NavButton
                label="Writing"
                icon={FiBarChart2}
                aria-current="page"
              /> */}
            </Stack>
            <Stack>
              <Text fontSize="sm" color="on-accent-muted" fontWeight="medium">
                Payroll
              </Text>
              <Stack spacing="1">
                {/* <NavButton label="Movies" icon={FiFilm} /> */}
                <SideBarButton
                  method={isClose}
                  label="Employees"
                  icon={FiUser}
                  to={"/employees"}
                />
                <SideBarButton
                  method={isClose}
                  label="Contract Blue Prints"
                  icon={FiLayers}
                  to={"/contract-blue-prints"}
                />
                <SideBarButton
                  method={isClose}
                  label="Contract Instances"
                  icon={FiActivity}
                  to={"/contract-instances"}
                />
                {/* <GeneratePayrollButton label="Generate Payroll" icon={FiShoppingBag} /> */}
                <ClickableButton
                  message={`Are you sure? This action will generate your employee's payslip for the past previous week, and email to you.`}
                  method={agent.HourlyFiling.GeneratePaySlips}
                  label="Generate PaySlips"
                  loaderTitle="Generating PaySlips"
                  loaderDescription="Payslips are being generated and emailed"
                  successDescription="PayRoll Generated. Payslips were emailed to respective employees"
                  errorDescription="PayRoll was already generated, or their exists an incompleted Weekly Filing"
                  icon={FiAirplay}
                />
              </Stack>
            </Stack>
            <Stack>
              <Text fontSize="sm" color="on-accent-muted" fontWeight="medium">
                Filings
              </Text>
              <Stack>
                <SideBarButton
                  method={isClose}
                  label="Current Week"
                  icon={FiCalendar}
                  to={"/currentWeekFiling"}
                />
              </Stack>

              <ClickableButton
                message={`Are you sure? This action will email your cashflow.`}
                method={agent.HourlyFiling.EmailCashFlow}
                label="Email Cashflow"
                loaderTitle="Emailing Cashflow"
                loaderDescription="Cash Flow is being emailed to respective recipient"
                successDescription="Cash Flow wass succesfully emailed"
                errorDescription="Cash Flow could not be sent at this time"
                icon={FiAirplay}
              />
              <ClickableButton
                message={`Are you sure? This action will email your hour tracker.`}
                method={agent.HourlyFiling.EmailHourTracker}
                label="Email Hour Tracker"
                loaderTitle="Emailing Hour Tracker"
                loaderDescription="Hour Tracker is being emailed to respective recipient"
                successDescription="Hour Tracker wass succesfully emailed"
                errorDescription="Hour Tracker could not be sent at this time"
                icon={FiAperture}
              />
            </Stack>
            <Stack>
              <Text fontSize="sm" color="on-accent-muted" fontWeight="medium">
                Filings
              </Text>
              <Stack>
                {/* <SideBarButton
                  method={isClose}
                  label="Services"
                  icon={FiMic}
                  to={"/services"}
                /> */}
                <SideBarButton
                  method={isClose}
                  label="Invoices"
                  icon={FiAward}
                  to={"/invoices"}
                />
              </Stack>
            </Stack>
            {/* <Stack>
              <Text fontSize="sm" color="on-accent-muted" fontWeight="medium">
                Social
              </Text>
              <Stack spacing="1">
                <NavButton label="Twitter" icon={FiTwitter} />

                <NavButton label="Instagram" icon={FiInstagram} />
                <NavButton label="Linkedin" icon={FiLinkedin} />
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>
      </Flex>
    </>
  );
}
