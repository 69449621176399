import { HStack, InputProps, useBreakpointValue, VStack } from "@chakra-ui/react";
import NIS from "../NIS";
import TRN from "../TRN";


export interface IDStackProps {
  additionalInputProps?: InputProps;
}
export default function IDStack(props: IDStackProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  if (isDesktop) {
    return (
      <HStack spacing={5}>
        <NIS additionalInputProps={additionalInputProps} />
        <TRN additionalInputProps={additionalInputProps} />
      </HStack>
    );
  }

  return (
    <>
      <VStack spacing={5}>
        <NIS additionalInputProps={additionalInputProps} />
        <TRN additionalInputProps={additionalInputProps} />
      </VStack>
    </>
  );
}
