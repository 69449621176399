import { Select, SelectProps } from "@chakra-ui/react";
import { CallResponse } from "api/interface";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";

export interface CustomSelectProps {
  formHook: UseFormReturn<any, any>;
  placeHolder?: string;
  name: string;
  defOptions?: { value: string; label: string }[];
  fetchDataCall: (params:URLSearchParams) => Promise<AxiosResponse<CallResponse<any>, any>>;
  isEnum?: boolean;
  additionalInputProps?: SelectProps;
}
export default function CustomSelect(props: CustomSelectProps) {
  const {
    additionalInputProps,
    fetchDataCall,
    formHook,
    name,
    defOptions = [],
    isEnum,
    placeHolder,
  } = props;

  const [options, setOptions] =
    useState<{ value: string; label: string }[]>(defOptions);
  useEffect(() => {
    fetchDataCall(new URLSearchParams()).then((x) => setOptions(x.data.result));
  }, []);

  return (
    <>
      <Controller
        control={formHook.control}
        render={({ field }) => (
          <Select {...field} {...additionalInputProps}>
            <option value={""}>Please choose here</option>
            {options?.map((option, index) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
        rules={{
          required: "Please provide an option",
        }}
        name={name}
      />
    </>
  );
}
