import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateContractInstance from "./CRUD/CreateContractInstance";
import DeleteContractInstance from "./CRUD/DeleteContractInstance";
import UpdateContractInstance from "./CRUD/UpdateContractInstance";
import ViewContractInstance from "./CRUD/ViewContractInstance";
import {
  CreateContractInstanceDTO,
  ContractInstanceDTO,
  UpdateContractInstanceDTO,
  ViewContractInstanceDTO,
} from "./interfaces";

export interface ContractInstancesProps {}
export default function ContractInstances(props: ContractInstancesProps) {
  const {} = props;

  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Employee",
      name: "name",
      order: 1,
    },
    {
      displayName: "Job",
      name: "jobTitle",
      order: 2,
    },
    {
      displayName: "Contract Status",
      name: "status",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    ContractInstanceDTO,
    CreateContractInstanceDTO,
    ViewContractInstanceDTO,
    UpdateContractInstanceDTO
  >({
    displayProps: {
      tableName: "Contract Instances",
      caption: "Table Showing Contract Instances",
      cardTitle: "Contract Instance",
    },
    headers,
    useTableProps: {
      Method: agent.ContractInstance.Items,
    },
    crudComponents: {
      create: <CreateContractInstance />,
      delete: <DeleteContractInstance />,
      update: <UpdateContractInstance />,
      view: <ViewContractInstance />,
    },
    calls: agent.ContractInstance,
    defaultValue: {
      contractBluePrintId: "",
      employeeId: "",
      endDate: new Date().toISOString().split("T")[0],
      hourlyWage: 1,
      startDate: new Date().toISOString().split("T")[0],
    },
  });
  return <>{component()}</>;
}
