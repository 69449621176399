import {
  useBreakpointValue,
  HStack,
  VStack,
  InputProps,
} from "@chakra-ui/react";
import GenericDate from "shared/components/date/GenericDate";

export interface DateStackProps {
  additionalInputProps?: InputProps;
}
export default function DateStack(props: DateStackProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  let g = [
    ["startDate", "Start Date"],
    ["endDate", "End Date"],
  ];
  const t = (
    <>
      {g.map((f) => {
        return (
          <GenericDate
            title={f[1]}
            property={f[0]}
            key={f[0]}
            additionalInputProps={additionalInputProps}
          />
        );
      })}
    </>
  );
  if (isDesktop) {
    return <HStack spacing={5}>{t}</HStack>;
  }

  return (
    <>
      <VStack spacing={5}>{t}</VStack>
    </>
  );
}
