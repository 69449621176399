import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Key from "./HTML/Key";
import JobDescription from "./HTML/Key";
import Value from "./HTML/Value";
import JobTitle from "./HTML/Value";

export interface ViewConfigurationProps {}
export default function ViewConfiguration(
  props: ViewConfigurationProps
) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      {" "}
      <Stack spacing={7}>
        <Key formHook={formHook} />
        <Value
          formHook={formHook}
          additionalInputProps={{ isReadOnly: true }}
        />
      </Stack>
    </>
  );
}
