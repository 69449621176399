import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateInvoice from "./CRUD/CreateInvoice";
import DeleteInvoice from "./CRUD/DeleteInvoice";
import UpdateInvoice from "./CRUD/UpdateInvoice";
import ViewInvoice from "./CRUD/ViewInvoice";
import {
  CreateInvoiceDTO,
  InvoiceDTO,
  UpdateInvoiceDTO,
  ViewInvoiceDTO,
} from "./interfaces";
import { FiMail } from "react-icons/fi";
import { useToast } from "shared/components/toast/useToast";

export interface InvoicesProps {}
export default function Invoices(props: InvoicesProps) {
  const {} = props;
  const { ProcessingToast, ErrorToast, SuccessToast, toast } = useToast();
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Date",
      name: "date",
      order: 1,
    },
    {
      displayName: "Name",
      name: "name",
      order: 2,
    },
    {
      displayName: "Total Cost",
      name: "totalCost",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    InvoiceDTO,
    CreateInvoiceDTO,
    ViewInvoiceDTO,
    UpdateInvoiceDTO
  >({
    displayProps: {
      tableName: "Invoices",
      caption: "Table Showing Invoices",
      cardTitle: "Invoice",
    },
    headers,
    useTableProps: {
      Method: agent.Invoice.Items,
    },
    crudComponents: {
      create: <CreateInvoice />,
      delete: <DeleteInvoice />,
      update: <UpdateInvoice />,
      view: <ViewInvoice />,
    },
    calls: agent.Invoice,
    defaultValue: {
      invoiceDate: new Date().toISOString().split("T")[0],
      items: [
        // {
        //   id:""
        //   name:"PPP",
        //   amountDescription:"svsedr",
        //   amountTotal:25,
        //   date:new Date().toISOString().split("T")[0],
        //   description:"fvvvf",
        //   manHours:1,
        //   workRate:2
        // }
      ],
      // purchaseOrderNumber: "34563egrger",
      // re: "53456rggr",
      // to: "rewgreg5657",
      // email: "davisrenaldo2@gmail.com",
      purchaseOrderNumber: "",
      re: "",
      to: "",
      email: "",
    },
    genericCrudOps: {
      update: true,
      delete: false,
      read: false,
      create: true,
    },
    additionalOptions: [
      {
        aria_label: "email-invoice",
        icon: <FiMail />,
        method: async (invoiceId) => {
          await ProcessingToast().then(async (proId) => {
            await agent.Invoice.EmailInvoice(invoiceId)
              .then(() => {
                toast?.close(proId);
                SuccessToast({ description: "Email was succesfully sent" });
              })
              .catch(() => {
                toast?.close(proId);
                ErrorToast({ description: "Email cannot be sent at this time" });
              });
          });
        },
        toolTip: "Email Invoice",
      },
    ],
  });
  return <>{component()}</>;
}
