import { requests } from "../general";

const SecurityEP = process.env.REACT_APP_API_SECURITY;

const Security = {
  Login: (data: { userName: string; password: string }) => {
    return requests.post(`${SecurityEP}/Login`, data);
  },
  Register: (data: string) => {
    return requests.post(`${SecurityEP}/Register`, data);
  },
  UpdatePassword: (data: { password: string; confirmPassword: string, email:string }) => {
    return requests.post(`${SecurityEP}/UpdatePassword`, data);
  },
  EmailNewPassWord:(data:string)=>{
    return requests.post(`${SecurityEP}/resetPasswordEmail`, data);
  }
};

export default Security;
