import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { useRef } from "react";

export interface GenericAlertProps {
  status: boolean;
  submit: () => void;
  reject: () => void;
  message: string;
  title: string;
}
export default function GenericAlert(props: GenericAlertProps) {
  const { reject, status, submit, message, title } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <>
      <AlertDialog
        isCentered
        isOpen={status}
        leastDestructiveRef={cancelRef}
        onClose={reject}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <Divider color={"green.300"}></Divider>
            <AlertDialogBody>{message}</AlertDialogBody>
            <Divider />
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={reject}>
                Cancel
              </Button>
              <Button variant={"primary"} onClick={submit} ml={3}>
                Continue
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
