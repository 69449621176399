import { InputProps, Input } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface ManHoursProps {
  additionalInputProps?: InputProps;
}
export default function ManHours(props: ManHoursProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("manHours", {
              required: `Man Hours is required`,
              min: { value: 0, message: "Hours has to be atleast 0" },
              maxLength:{value:7, message:"Hours cannot be more than 9,999,999"}
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            type={"number"}
            {...additionalInputProps}
          />
        }
        label={`Accumulated Man Hours`}
        placeHolder=""
        errorMessage={errors["manHours"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
