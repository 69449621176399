import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Logo } from "./Logo";
import SideBar from "./SideBar";
import ToggleButton from "./ToggleButton";

export interface NavBarProps {}
export default function NavBar(props: NavBarProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [close, setClose] = useState(Math.random());
  useEffect(() => {
    onToggle();
  }, [close]);
  const {} = props;
  return (
    <>
      {" "}
      <Box width="full" py="4" px={{ base: "4", md: "8" }} bg="bg-accent">
        <Flex justify="space-between">
          <Logo />
          <ToggleButton
            isOpen={isOpen}
            aria-label="Open Menu"
            onClick={onToggle}
          />
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            isFullHeight
            preserveScrollBarGap
            // Only disabled for showcase
            trapFocus={false}
          >
            <DrawerOverlay />
            <DrawerContent>
              <SideBar isClose={setClose} />
            </DrawerContent>
          </Drawer>
        </Flex>
      </Box>
    </>
  );
}
