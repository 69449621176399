import { Input, InputProps } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface NISProps {
  additionalInputProps?: InputProps;
}
export default function NIS(props: NISProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { additionalInputProps } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("nIS", {
              required: `NIS is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
           
            {...additionalInputProps}
          />
        }
        label={"NIS"}
        placeHolder=""
        errorMessage={errors["nIS"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
