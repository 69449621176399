import {
  Thead,
  Tr,
  Th,
  HStack,
  Checkbox,
  Icon,
  Text,
  Td,
} from "@chakra-ui/react";
import { BaseSearchParams } from "api/interface";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import { ActiveHeaderProps, TableColumnHeaderProps } from "./interfaces";
import TableHead from "./TableHead";

export interface TableHeaderProps {
  headers: TableColumnHeaderProps[];
  optionsStatus: "show" | "hide";
  baseParam: BaseSearchParams;
  setBaseParam: React.Dispatch<React.SetStateAction<BaseSearchParams>>;
}

export default function TableHeader(props: TableHeaderProps) {
  const { baseParam, headers, optionsStatus, setBaseParam } = props;

  const [activeHeader, setActiveHeader] = useState<ActiveHeaderProps>({});
  useEffect(() => {
    if (Object.keys(activeHeader).length !== 0) {
      const timeOutId = setTimeout(() => {
        if (activeHeader.activeHeaderId) {
          setBaseParam({
            ...baseParam,
            orderBy:
              activeHeader.activeName === undefined
                ? undefined
                : `${activeHeader.activeName} ${
                    activeHeader.sortDirection === "descending" ? "desc" : null
                  }`,
          });
        }
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [activeHeader]);
  return (
    <>
      <Thead>
        <Tr>
          {headers
            .sort((a, b) => a.order - b.order)
            .map((x) => {
              return (
                <Fragment key={x.order}>
                  <TableHead
                    sortable={x.isSortable}
                    sortName={x.name}
                    arrowDown={<IoMdArrowDown />}
                    arrowUp={<IoMdArrowUp />}
                    activeHeader={activeHeader}
                    setActiveHeader={setActiveHeader}
                    id={x.order}
                    title={x.displayName}
                  />
                </Fragment>
              );
            })}

          <Th></Th>
        </Tr>
      </Thead>
    </>
  );
}
