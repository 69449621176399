import {
  InputProps,
  useBreakpointValue,
  HStack,
  VStack,
} from "@chakra-ui/react";
import Date from "./Date";

export interface DateStackProps {
  additionalInputProps?: InputProps;
}
export default function DateStack(props: DateStackProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  let g = [
    ["startDate", "Start Date"],
    ["expectedEndDate", "Expected Date of Completion"],
  ];
  const t = (
    <>
      {g.map((f) => {
        return (
          <Date
            title={f[1]}
            property={f[0]}
            key={f[0]}
            additionalInputProps={additionalInputProps}
          />
        );
      })}
    </>
  );
  if (isDesktop) {
    return <HStack spacing={5}>{t}</HStack>;
  }

  return (
    <>
      <VStack spacing={5}>{t}</VStack>
    </>
  );
}
