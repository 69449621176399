import { As, Button, HStack, Icon, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export interface SideBarButtonProps {
  icon: As;
  label: string;
  to: string;
  method?: React.Dispatch<React.SetStateAction<number>>;
}
export default function SideBarButton(props: SideBarButtonProps) {
  const { icon, label, to, method, ...buttonProps } = props;
  return (
    <>
      <Button
        to={to}
        as={NavLink}
        variant="ghost-on-accent"
        justifyContent="start"
        {...buttonProps}
        onClick={() => {
          if (method !== undefined) method(Math.random());
        }}
      >
        <HStack spacing="3">
          <Icon as={icon} boxSize="6" color="on-accent-subtle" />
          <Text>{label}</Text>
        </HStack>
      </Button>
    </>
  );
}
