import { Button, ButtonGroup } from "@chakra-ui/react";
import CrudOption from "./CrudOption";
import { FiEdit2, FiTrash2, FiEye } from "react-icons/fi";
import { AdditionalOption, CrudOptions as DD } from "hooks/crud/interfaces";

export interface CrudOptionsProps {
  id: string;
  viewMethod: (id: string) => void;
  updateMethod: (id: string) => void;
  deleteMethod: (id: string) => void;
  crudOptions?: DD;
  additionalOptions?: AdditionalOption[];
}
export default function CrudOptions(props: CrudOptionsProps) {
  const {
    id,
    viewMethod,
    updateMethod,
    deleteMethod,
    crudOptions,
    additionalOptions,
  } = props;
  return (
    <>
      <ButtonGroup>
        {crudOptions?.read && (
          <>
            <CrudOption
              aria_label="view-button"
              icon={<FiEye />}
              method={() => viewMethod(id)}
              toolTip="View"
            />
          </>
        )}
        {crudOptions?.update && (
          <>
            <CrudOption
              aria_label="update-button"
              icon={<FiEdit2 />}
              method={() => updateMethod(id)}
              toolTip="Edit"
            />
          </>
        )}
        {crudOptions?.delete && (
          <>
            <CrudOption
              aria_label="delete-button"
              icon={<FiTrash2 />}
              method={() => deleteMethod(id)}
              toolTip="Delete"
            />
          </>
        )}

        {additionalOptions !== undefined && (
          <>
            {additionalOptions!.map((option) => {
              return (
                <CrudOption
                  key={option.aria_label}
                  {...{
                    aria_label: option.aria_label,
                    icon: option.icon,
                    method: () => option.method(id),
                    toolTip: option.toolTip,
                  }}
                />
              );
            })}
          </>
        )}
      </ButtonGroup>
    </>
  );
}
