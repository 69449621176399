import { InputProps, Input } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface TRNProps {
  additionalInputProps?: InputProps;
}
export default function TRN(props: TRNProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const {
    
    additionalInputProps,
  } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("tRN", {
              required: `TRN is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            type={"text"}
            {...additionalInputProps}
          />
        }
        label={"TRN"}
        placeHolder=""
        errorMessage={errors["tRN"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
