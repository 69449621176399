import { InputGroup, InputLeftElement, Icon, Input } from "@chakra-ui/react";
import { BaseSearchParams } from "api/interface";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

export interface TableSearchProps {
  baseParam: BaseSearchParams;
  setBaseParam: React.Dispatch<React.SetStateAction<BaseSearchParams>>;
}
export default function TableSearch(props: TableSearchProps) {
  const { baseParam, setBaseParam } = props;
  const [query, setQuery] = useState<string | null>(null);

  useEffect(() => {
    if (query !== null) {
      const timeoutId = setTimeout(() => {
        setBaseParam({ ...baseParam, searchCriteria: query, pageNumber: 1 });
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [query]);

  return (
    <>
      <InputGroup maxW="xs">
        <InputLeftElement pointerEvents="none">
          <Icon as={FiSearch} color="muted" boxSize="5" />
        </InputLeftElement>
        {/* <Input placeholder="Search" /> */}
        <Input
          value={query === null ? "" : query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </InputGroup>
    </>
  );
}
