import { Tooltip, IconButton } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface CrudOptionProps {
  aria_label: string;
  toolTip: string;
  method: () => void;
  icon: ReactElement;
}
export default function CrudOption(props: CrudOptionProps) {
  const { aria_label, icon, method, toolTip } = props;
  return (
    <>
      <Tooltip label={toolTip} openDelay={300}>
        <IconButton
          mr={2}
          aria-label={aria_label}
          onClick={method}
          icon={icon}
        ></IconButton>
      </Tooltip>
    </>
  );
}
