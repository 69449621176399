import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import JobDescription from "./HTML/JobDescription";
import JobTitle from "./HTML/JobTitle";
import ServicePrice from "./HTML/ServicePrice";
import BasicInfoStack from "./HTML/Stacks/BasicInfoStack";
import DateStack from "./HTML/Stacks/DateStack";

export interface ViewServiceProps {}
export default function ViewService(props: ViewServiceProps) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      {" "}
      <Stack spacing={7}>
        <BasicInfoStack additionalInputProps={{ isReadOnly: true }} />
        <ServicePrice additionalInputProps={{ isReadOnly: true }} />
        <DateStack additionalInputProps={{ isReadOnly: true }} />
      </Stack>
    </>
  );
}
