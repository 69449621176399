import { FormControl, FormLabel, Input, InputProps } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface NameProps {
  additionalInputProps?: InputProps;
}
export default function PurchaseOrder(props: NameProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("purchaseOrderNumber", {
              required: `Purchase Order is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...additionalInputProps}
          />
        }
        label={`Purchase Order Number`}
        placeHolder=""
        errorMessage={
          errors["purchaseOrderNumber"]?.message as string | undefined
        }
        isRequired={true}
      />
    </>
  );
}
