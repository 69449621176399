import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateEmployee from "./CRUD/CreateEmployee";
import DeleteEmployee from "./CRUD/DeleteEmployee";
import UpdateEmployee from "./CRUD/UpdateEmployee";
import ViewEmployee from "./CRUD/ViewEmployee";
import {
  CreateEmployeeDTO,
  EmployeeDTO,
  UpdateEmployeeDTO,
  ViewEmployeeDTO,
} from "./interfaces";

export interface EmployeesProps {}
export default function Employees(props: EmployeesProps) {
  const {} = props;

  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "First Name",
      name: "firstName",
      order: 1,
    },
    {
      displayName: "Last Name",
      name: "lastName",
      order: 2,
    },
    // {
    //   displayName: "Address",
    //   name: "address",
    //   order: 3,
    // },
  ];

  const { component } = useCrudTable<
    EmployeeDTO,
    CreateEmployeeDTO,
    ViewEmployeeDTO,
    UpdateEmployeeDTO
  >({
    displayProps: {
      tableName: "Employees",
      caption: "Table Showing Employees",
      cardTitle: "Employee",
    },
    headers,
    useTableProps: {
      Method: agent.Employee.Items,
    },
    crudComponents: {
      create: <CreateEmployee />,
      delete: <DeleteEmployee />,
      update: <UpdateEmployee />,
      view: <ViewEmployee />,
    },
    calls: agent.Employee,
    defaultValue: {
      firstName: "",
      middleName: "",
      lastName: "",
      nIS: "",
      tRN: "",
    },
  });
  return <>{component()}</>;
}
