import { InputProps, Textarea, TextareaProps } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface AddressProps {
  formHook: UseFormReturn<any, any>;
  additionalInputProps?: TextareaProps;
}
export default function JobDescription(props: AddressProps) {
  const {
    formHook: {
      register,
      formState: { errors },
    },
    additionalInputProps,
  } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Textarea
            {...register("jobDescription", {
              minLength: {
                value:6,
                message:"Description needs to be at least 6 characters long"
              },
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...additionalInputProps}
          />
        }
        label={"jobDescription"}
        placeHolder=""
        errorMessage={errors["jobDescription"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
