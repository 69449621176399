import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import JobDescription from "./HTML/JobDescription";
import JobTitle from "./HTML/JobTitle";

export interface DeleteContractBluePrintProps {}
export default function DeleteContractBluePrint(
  props: DeleteContractBluePrintProps
) {
  const formHook = useFormContext();
  return (
    <>
      {" "}
      <Stack spacing={7}>
        <JobTitle
          formHook={formHook}
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <JobDescription
          formHook={formHook}
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
      </Stack>
    </>
  );
}
