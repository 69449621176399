import {
    ContractInstanceDTO,
    CreateContractInstanceDTO,
    ViewContractInstanceDTO,
    UpdateContractInstanceDTO,
  } from "components/Payroll/ContractInstance/interfaces";
  import ff, { gg } from "../general";
  const EP = process.env.REACT_APP_API_CONTRACT_INSTANCE;
  const ContractInstance = gg<
    ContractInstanceDTO,
    CreateContractInstanceDTO,
    ViewContractInstanceDTO,
    UpdateContractInstanceDTO
  >(EP!);
  
  export default ContractInstance;
  