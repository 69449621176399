import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import {
  ConfigurationDTO,
  CreateConfigurationDTO,
  UpdateConfigurationDTO,
  ViewConfigurationDTO,
} from "../Config/interfaces";
import UpdateConfiguration from "./CRUD/UpdateConfiguration";
import ViewConfiguration from "./CRUD/ViewConfiguration";
import {} from "./interfaces";

export interface ConfigurationsProps {}
export default function Configurations(props: ConfigurationsProps) {
  const {} = props;

  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Key",
      name: "key",
      order: 1,
    },
    {
      displayName: "Value",
      name: "value",
      order: 2,
    },
  ];

  const { component } = useCrudTable<
    ConfigurationDTO,
    CreateConfigurationDTO,
    ViewConfigurationDTO,
    UpdateConfigurationDTO
  >({
    displayProps: {
      tableName: "Configurations",
      caption: "Table Showing Configurations",
      cardTitle: "Configurations",
    },
    headers,
    useTableProps: {
      Method: agent.Configuration.Items,
    },
    crudComponents: {
      create: <></>,
      delete: <></>,
      update: <UpdateConfiguration />,
      view: <ViewConfiguration />,
    },
    calls: agent.Configuration,
    defaultValue: {
      jobTitle: "",
      jobDescription: "",
    },
    genericCrudOps: {
      create: false,
      delete: false,
      read:true,
      update:true
    },
  });
  return <>{component()}</>;
}
