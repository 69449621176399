import { InputProps, Input } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface DateProps {
  additionalInputProps?: InputProps;
  title: string;
  property: string;
}
export default function Date(props: DateProps) {
  const {
    register,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const { additionalInputProps, property, title } = props;
  console.log(getValues()["startDate"]);
  return (
    <>
      <GenericFormControl
        input={
          <Controller
            rules={{ required: "DDDD" }}
            control={control}
            name={property}
            render={({
              field: { onChange, name, value },
              fieldState: { isDirty, error },
              formState: { errors },
            }) => (
              <Input
                value={value || ""}
                onChange={(date) => {
                  onChange(date);
                }}
                type="date"
                id="large"
                size="lg"
                placeholder=" "
                data-peer
                {...additionalInputProps}
              />
            )}
          />
        }
        label={title}
        placeHolder=""
        errorMessage={errors[property]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
