import {
  Box,
  HStack,
  SelectProps,
  Stack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import agent from "api/agent";
import { CallResponse } from "api/interface";
import { AxiosResponse } from "axios";
import { Fragment } from "react";
import EmployeeName from "../HTML/EmployeeName";
import InstanceSelect from "../HTML/InstanceSelect";

export interface SelectStackProps {
  additionalInputProps?: SelectProps;
  isCreate?: boolean;
}
export default function SelectStack(props: SelectStackProps) {
  const { additionalInputProps, isCreate = false } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  let g: [
    string,
    string,
    boolean,
    (params: URLSearchParams) => Promise<AxiosResponse<CallResponse<any>, any>>
  ][] = [
    ["employeeId", "Employee ", false, agent.Employee.Unemployed],
    [
      "contractBluePrintId",
      " Contract Blue Print",
      false,
      agent.ContractBluePrint.Select!,
    ],
  ];

  const t = (
    <>
      {g.map((ff) => {
        if (!isCreate && ff[0] === "employeeId")
          return (
            <Fragment key={ff[0]}>
              <EmployeeName />
            </Fragment>
          );
        return (
          <Box key={ff[0]} mb={isDesktop ? "inherit" : 5} width="100%">
            <InstanceSelect
              fetchDataCall={ff[3]}
              isEnum={ff[2]}
              property={ff[0]}
              title={ff[1]}
              additionalInputProps={{
                ...additionalInputProps,
                isDisabled: additionalInputProps?.isReadOnly,
              }}
            />
          </Box>
        );
      })}
    </>
  );
  if (isDesktop)
    return (
      <>
        <HStack>{t}</HStack>
      </>
    );
  return (
    <>
      <VStack>{t}</VStack>
    </>
  );
}
