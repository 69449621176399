import AuthenticationContext from "Contexts/AuthenticationContext";
import { useContext } from "react";

export default function useAuthenticationContext() {
  const { claims } = useContext(AuthenticationContext);


  return{
    email:claims.find(x=>x.property==="email")!.value
  }
}
