import { As, Button, HStack, Icon, Text } from "@chakra-ui/react";
import agent from "api/agent";
import { CallResponse } from "api/interface";
import { AxiosResponse } from "axios";
import { CrudTypes } from "hooks/crud/interfaces";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericAlert from "shared/components/alert/GenericAlert";
import { Sleep } from "shared/components/functions/generic/asyncFunctions";
import CrudModal, {
  CrudModalProps,
} from "shared/components/modal/crud-modal/CrudModal";
import GenericModal, {
  GenericModalProps,
} from "shared/components/modal/generic/GenericModal";
import { useToast } from "shared/components/toast/useToast";

export interface ClickableButtonProps {
  icon: As;
  label: string;
  loaderTitle: string;
  loaderDescription: string;
  successDescription: string;
  errorDescription: string;
  method: () => Promise<AxiosResponse<CallResponse<any>, any>>;
  message?: string;
  title?: string;
}
export default function ClickableButton(props: ClickableButtonProps) {
  const {
    icon,
    label,
    loaderDescription,
    loaderTitle,
    successDescription,
    errorDescription,
    method,
    message = "Are you sure?",
    title = "Alert Prompt",
    ...buttonProps
  } = props;
  const {
    ProcessingToast,
    ErrorToast,
    SuccessToast,
    CustomProcessingToast,
    toast,
  } = useToast();
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState({
    show: false,
  });

  return (
    <>
      <Button
        onClick={() => setModalProps({ show: true })}
        variant="ghost-on-accent"
        justifyContent="start"
        {...buttonProps}
      >
        <HStack spacing="3">
          <Icon as={icon} boxSize="6" color="on-accent-subtle" />
          <Text>{label}</Text>
        </HStack>
      </Button>
      <GenericAlert
        title={title}
        message={message}
        reject={() => {
          setModalProps({ show: false });
        }}
        status={modalProps.show}
        submit={async () => {
          await Sleep(100).then(async (x) => {
            await CustomProcessingToast(loaderTitle, loaderDescription).then(
              async (processingId) => {
                await Sleep(3000).then(async (x) => {
                  await method()
                    .then(async (x) => {
                      toast?.close(processingId);
                      await SuccessToast({
                        description: successDescription,
                        title: "Success",
                        position: "top",
                        duration: 5000,
                      })
                        .then(() => setModalProps({ show: false }))
                        .then((x) => navigate("/"));
                    })
                    .catch((err) => {
                      toast?.close(processingId);
                      ErrorToast({
                        description: errorDescription,
                        title: "Error",
                        position: "top",
                        duration: 5000,
                      });
                      setModalProps({ show: false });
                    });
                });
              }
            );
          });
        }}
      />

      {/* <CrudModal
        actionProps={{
          cancelMethod() {
            setModalProps({ show: false });
          },
          async processMethod() {},
          type: CrudTypes.Proceed,
          isLoading: false,
        }}
        modalProps={{
          size: "md",
          modalHeaderProps: <>Confirming Action</>,
          modalBodyProps: <></>,
          modalFooterProps: <></>,
          isLoading: false,
          onClose() {},
          status: modalProps.show,
        }}
      /> */}
    </>
  );
}
