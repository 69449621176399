import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import CrudModalLoader from "shared/components/loader/CrudModalLoader";

export interface GenericModalProps {
  isLoading:boolean
  status: boolean;
  onClose: () => void;
  modalHeaderProps: ReactElement;
  modalBodyProps: ReactElement;
  modalFooterProps: ReactElement;
  size?:
    | "md"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | (string & {})
    | "xs"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
}
export default function GenericModal(props: GenericModalProps) {
  const {
    modalBodyProps,
    modalFooterProps,
    modalHeaderProps,
    onClose,
    status,
    isLoading,
    size = "xl",
  } = props;
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={status}
        onClose={onClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            <>{modalHeaderProps}</>
            <CrudModalLoader isLoading={isLoading}/>
          </ModalHeader>
          <ModalCloseButton />
          <Divider></Divider>
          <ModalBody mt={5}>{modalBodyProps}</ModalBody>
          <Divider></Divider>
          <ModalFooter>{modalFooterProps}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
