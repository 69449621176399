import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateContractBluePrint from "./CRUD/CreateContractBluePrint";
import DeleteContractBluePrint from "./CRUD/DeleteContractBluePrint";
import UpdateContractBluePrint from "./CRUD/UpdateContractBluePrint";
import ViewContractBluePrint from "./CRUD/ViewContractBluePrint";
import {
  CreateContractBluePrintDTO,
  ContractBluePrintDTO,
  UpdateContractBluePrintDTO,
  ViewContractBluePrintDTO,
} from "./interfaces";

export interface ContractBluePrintsProps {}
export default function ContractBluePrints(props: ContractBluePrintsProps) {
  const {} = props;

  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Job Title",
      name: "jobTitle",
      order: 1,
    },
    {
      displayName: "Job Description",
      name: "jobDescription",
      order: 2,
    },   
  ];

  const { component } = useCrudTable<
    ContractBluePrintDTO,
    CreateContractBluePrintDTO,
    ViewContractBluePrintDTO,
    UpdateContractBluePrintDTO
  >({
    displayProps: {
      tableName: "Contract Blue Prints",
      caption: "Table Showing Contract Blue Prints",
      cardTitle: "Contract Blue Print",
    },
    headers,
    useTableProps: {
      Method: agent.ContractBluePrint.Items,
    },
    crudComponents: {
      create: <CreateContractBluePrint />,
      delete: <DeleteContractBluePrint />,
      update: <UpdateContractBluePrint />,
      view: <ViewContractBluePrint />,
    },
    calls: agent.ContractBluePrint,
    defaultValue: {
     jobTitle:"",
     jobDescription:""
    },
  });
  return <>{component()}</>;
}
