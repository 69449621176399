import { CallResponse, IAgentGenericCalls } from "api/interface";
import { AxiosResponse } from "axios";
import {
  EmployeeDTO,
  CreateEmployeeDTO,
  ViewEmployeeDTO,
  UpdateEmployeeDTO,
} from "components/Payroll/Employee/interfaces";
import ff, { gg, requests } from "../general";
const EmployeeEP = process.env.REACT_APP_API_EMPLOYEE;
const Employee:IEmployeeCalls = {
  ...gg<
  EmployeeDTO,
  CreateEmployeeDTO,
  ViewEmployeeDTO,
  UpdateEmployeeDTO
>(EmployeeEP!),
Unemployed:()=>{
  return requests.get(`${EmployeeEP}/UnemployedSelect`, new URLSearchParams());
}
}
Employee.Select = () => {
  return requests.get(`${EmployeeEP}/Select`, new URLSearchParams());
};

export interface IEmployeeCalls extends IAgentGenericCalls<EmployeeDTO,
CreateEmployeeDTO,
ViewEmployeeDTO,
UpdateEmployeeDTO>{
  Unemployed: (   ) => Promise<AxiosResponse<CallResponse<EmployeeDTO[]>, any>>;
}



export default Employee;
