import AuthenticationContext from "Contexts/AuthenticationContext";
import { ReactElement, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Login from "./Login";

export interface AuthorizeProps {
  element: ReactElement;
}
export default function Authorize(props: AuthorizeProps) {
  const { element } = props;
  const { claims, isAuthenticated } = useContext(AuthenticationContext);  
  const isUpdate = claims?.find((x) => x.property === "updatePassword")?.value;
  const navigate = useNavigate();
  if (!isAuthenticated) {
    
      navigate("/login")
      return null;
  }
  if (isUpdate === "True") {
    
    navigate("/updatePassword")
    return null;
}




  return <>{element}</>;
}
