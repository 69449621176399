import {
  useBreakpointValue,
  HStack,
  VStack,
  InputProps,
} from "@chakra-ui/react";
import GenericDate from "shared/components/date/GenericDate";

export interface InvoiceDateProps {
  additionalInputProps?: InputProps;
}
export default function InvoiceDate(props: InvoiceDateProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  let g = [
    ["startDate", "Start Date"],
    ["endDate", "End Date"],
  ];
  return (
    <>
      <GenericDate
        title={"Date Of Invoice"}
        property={"invoiceDate"}
        additionalInputProps={additionalInputProps}
      />
    </>
  );
}
