import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import agent from "api/agent";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import GenericFormControl from "shared/components/form/GenericFormControl";
import { Sleep } from "shared/components/functions/generic/asyncFunctions";
import { useToast } from "shared/components/toast/useToast";
import { LoginDTO } from "./interfaces";
import useSecurity from "./useSecurity";

export interface LoginProps {}
export default function Login(props: LoginProps) {
  const {} = props;
  const { ErrorToast, SuccessToast } = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const {
    error,
    formHook: {
      register,
      handleSubmit,
      formState: { errors },
    },
    submit,
  } = useSecurity({ type: "login" });
  return (
    <>
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            {/* <Logo /> */}
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Log in to your account
              </Heading>
              {/* <HStack spacing="1" justify="center">
                <Text color="muted">Don't have an account?</Text>
                <Button variant="link" colorScheme="blue">
                  Sign up
                </Button>
              </HStack> */}
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg-surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <GenericFormControl
                  input={
                    <>
                      <Input
                        {...register("userName", {
                          required: "User Name is Required",
                        })}
                      />
                    </>
                  }
                  label="User Name"
                  errorMessage={
                    errors["userName"]?.message as string | undefined
                  }
                  isRequired={true}
                  placeHolder={""}
                />
                <GenericFormControl
                  input={
                    <>
                      <InputGroup>
                        <InputLeftElement children={<FaLock />} />
                        <Input
                          type={show ? "text" : "password"}
                          {...register("password", {
                            required: "Password is Required",
                          })}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </>
                  }
                  label="Password"
                  errorMessage={
                    errors["password"]?.message as string | undefined
                  }
                  isRequired={true}
                  placeHolder={""}
                />
              </Stack>
              <HStack justify="space-between">
                <Button
                  onClick={() => {
                    navigate("/forgetPassword");
                  }}
                  variant="link"
                  colorScheme="blue"
                  size="sm"
                >
                  Forgot password?
                </Button>
              </HStack>
              <Stack spacing="6">
                <Button onClick={handleSubmit(submit, error)} variant="primary">
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
