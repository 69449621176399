import { Stack, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import JobDescription from "./HTML/JobDescription";
import JobTitle from "./HTML/JobTitle";

export interface CreateContractBluePrintProps {}
export default function CreateContractBluePrint(
  props: CreateContractBluePrintProps
) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <JobTitle formHook={formHook} />
        <JobDescription formHook={formHook} />
      </Stack>
    </>
  );
}
