import { Input, InputProps, Textarea, TextareaProps } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface KeyProps {
  formHook: UseFormReturn<any, any>;
  additionalInputProps?: InputProps;
}
export default function Key(props: KeyProps) {
  const {
    formHook: {
      register,
      formState: { errors },
    },
    additionalInputProps,
  } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("key")}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            isReadOnly={true}
            {...additionalInputProps}
          />
        }
        label={"Key"}
        placeHolder=""
        errorMessage={errors["key"]?.message as string | undefined}
      />
    </>
  );
}
