import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import HourlyWage from "./HTML/HourlyWage";
import DateStack from "./Stacks/DateStack";
import SelectStack from "./Stacks/SelectStack";

export interface CreateContractInstanceProps {}
export default function CreateContractInstance(
  props: CreateContractInstanceProps
) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <SelectStack isCreate = {true} />
        <DateStack />
        <HourlyWage />
      </Stack>
    </>
  );
}
