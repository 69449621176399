import { Select, SelectProps } from "@chakra-ui/react";
import { CallResponse } from "api/interface";
import { AxiosResponse } from "axios";
import { Controller, useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";
import CustomSelect from "shared/components/select/CustomSelect";

export interface InstanceSelectProps {
  additionalInputProps?: SelectProps;
  title: string;
  property: string;
  isEnum: boolean;
  fetchDataCall: (params:URLSearchParams) => Promise<AxiosResponse<CallResponse<any>, any>>;
}
export default function InstanceSelect(props: InstanceSelectProps) {
  const formHook = useFormContext();
  const { additionalInputProps, property, title, fetchDataCall, isEnum } =
    props;
  return (
    <>
      <GenericFormControl
        input={        
          <CustomSelect
            isEnum={isEnum}
            fetchDataCall={fetchDataCall}
            formHook={formHook}
            name={property}
            additionalInputProps={additionalInputProps}
          />
        }
        label={title}
        placeHolder=""
        errorMessage={
          formHook.formState.errors[property]?.message as string | undefined
        }
        isRequired={true}
      />
    </>
  );
}
