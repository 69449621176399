import {
  Heading,
  Stack,
  useBreakpointValue,
  Text,
  Box,
  HStack,
  useColorModeValue,
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Editable,
  EditableInput,
  EditablePreview,
  Tooltip,
  Th,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useEditableControls,
  ButtonGroup,
  IconButton,
  Button,
  Flex,
  Tfoot,
} from "@chakra-ui/react";
import { useToast } from "shared/components/toast/useToast";
import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import useTable from "hooks/crud/useTable";
import { Fragment, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiEdit2 } from "react-icons/fi";
import CrudTable from "shared/components/crud-table/CrudTable";
import { Sleep } from "shared/components/functions/generic/asyncFunctions";
import TableLoader from "shared/components/loader/TableLoader";
import Pagination from "shared/components/pagination/Pagination";
import TableSearch from "shared/components/search/TableSearch";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import TableHeader from "shared/components/table/TableHeader";
import UpdateHourlyFiling from "./CRUD/UpdateHourlyFiling";
import {
  CreateHourlyFilingDTO,
  HourlyFilingDTO,
  UpdateHourlyFilingDTO,
  ViewHourlyFilingDTO,
} from "./interfaces";
import moment from "moment";
export interface HourlyFilingsProps {}
export default function HourlyFilings(props: HourlyFilingsProps) {
  const {} = props;
  const [dates, setDates] = useState<string[]>([]);
  useEffect(() => {
    async function getDates() {
      await agent.HourlyFiling.GetCurrentFilingDates().then((response) => {
        setDates(response.data.result);
      });
    }
    getDates();
  }, []);
  const { ErrorToast, SuccessToast } = useToast();

  const {
    baseParams,
    itemsResponse: { items, pagination },
    setBaseParams,
    tableLoading,
    triggerRefresh,
    setTableLoading,
  } = useTable<HourlyFilingDTO>({
    Method: agent.HourlyFiling.CurrentFilings,
  });
  const y = moment();
  const id = useRef("");
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Name",
      name: "name",
      order: 1,
    },
    {
      displayName: "Friday",
      name: "friday",
      order: 4,
      isSortable: false,
    },
    {
      displayName: "Sunday",
      name: "sunday",
      order: 5,
      isSortable: false,
    },
    {
      displayName: "Monday",
      name: "monday",
      order: 6,
      isSortable: false,
    },
    {
      displayName: "Tuesday",
      name: "tuesday",
      order: 7,
      isSortable: false,
    },
    {
      displayName: "Wednesday",
      name: "wednesday",
      order: 8,
      isSortable: false,
    },
    {
      displayName: "Thursday",
      name: "thursday",
      order: 9,
      isSortable: false,
    },
    {
      displayName: "Special Payment",
      name: "specialPayment",
      order: 10,
      isSortable: false,
    },
    {
      displayName: "",
      name: "submit",
      order: 99,
      isSortable: false,
    },
  ];
  const formHook = useForm();

  interface ECProps {
    property: string;
    parent: string;
  }

  function EditableControls(props: ECProps) {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return (
      <>
        {isEditing && (
          <>
            <NumberInput min={0} max={15}>
              <NumberInputField
                onFocus={(e) => e.target.select()}
                as={EditableInput}
                {...formHook.register(`${props.property}`)}
              />
              {/* <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper> */}
            </NumberInput>
            <ButtonGroup
              justifyContent="end"
              size="sm"
              w="full"
              spacing={2}
              mt={2}
            >
              <Button {...getSubmitButtonProps()}>Yes</Button>
              <Button {...getCancelButtonProps()}>No</Button>
            </ButtonGroup>
          </>
        )}
        {isEditing !== true && (
          <>
            {/* <Flex justifyContent="center">
              <Button size="sm" {...getEditButtonProps()}>
                Edit
              </Button>
            </Flex> */}
          </>
        )}
      </>
    );
  }

  const component = (
    <>
      <TableLoader isLoading={tableLoading} />

      <FormProvider {...formHook}>
        <Stack spacing={{ base: "8", lg: "6" }} height="full">
          <Stack
            spacing="4"
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
            align={{ base: "start", lg: "center" }}
          >
            <Stack spacing="1">
              <Heading
                size={useBreakpointValue({ base: "xs", lg: "sm" })}
                fontWeight="medium"
              >
                Current Week Hour Filings
              </Heading>
              <Text color="muted">{"Hour Filing for the current week"}</Text>
            </Stack>
          </Stack>
          {/* Table */}
          <Box
            bg="bg-surface"
            boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
            borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
          >
            <Stack spacing={5}>
              <Box px={{ base: "4", md: "6" }} pt="5">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                >
                  <Text fontSize="lg" fontWeight="medium">
                    Table
                  </Text>
                  <TableSearch
                    baseParam={baseParams}
                    setBaseParam={setBaseParams}
                  />
                </Stack>
              </Box>
              <Box overflowX={"auto"}>
                <Table size={"sm"}>
                  <TableHeader
                    {...{
                      baseParam: baseParams,
                      setBaseParam: setBaseParams,
                      headers,
                      optionsStatus: "show",
                    }}
                  />
                  <Tbody>
                    {items.length === 0 && (
                      <>
                        <Tr>
                          <Td colSpan={2}>No Data is Present</Td>
                        </Tr>
                      </>
                    )}
                    {items.map((item) => {
                      return (
                        <Fragment key={item.id}>
                          <Tr>
                            <Td>{item.name}</Td>
                            {item.dailyHoursWorked.map((c) => {
                              if (c.isActive) {
                                if (c.isDisabled) {
                                  return <Td key={c.id}>{c.hoursWorked}</Td>;
                                } else {
                                  return (
                                    <Fragment key={c.id}>
                                      <Td>
                                        {/* <Center> */}
                                        <Editable
                                          isPreviewFocusable={true}
                                          selectAllOnFocus={false}
                                          defaultValue={c.hoursWorked.toString()}
                                        >
                                          <Tooltip
                                            label="Click to edit"
                                            colorScheme={"facebook"}
                                          >
                                            <EditablePreview py={2} px={4} />
                                          </Tooltip>

                                          <EditableControls
                                            parent={item.id}
                                            property={c.day}
                                          />
                                        </Editable>
                                        {/* </Center> */}
                                      </Td>
                                    </Fragment>
                                  );
                                }
                              } else {
                                return <Td key={c.id}></Td>;
                              }
                            })}
                            <Td>
                              {/* <Center> */}
                              <Editable
                                isPreviewFocusable={true}
                                selectAllOnFocus={false}
                                defaultValue={item.specialPayment.toString()}
                              >
                                <Tooltip
                                  label="Click to edit"
                                  colorScheme={"facebook"}
                                >
                                  <EditablePreview py={2} px={4} />
                                </Tooltip>

                                <EditableControls
                                  parent={item.id}
                                  property={"specialPayment"}
                                />
                              </Editable>
                              {/* </Center> */}
                            </Td>
                            <Td>
                              <Button
                                isLoading={
                                  tableLoading && id.current === item.id
                                }
                                onClick={async () => {
                                  id.current = item.id;
                                  setTableLoading(true);
                                  await Sleep(500).then((x) => {
                                    agent.HourlyFiling.Update({
                                      id: item.id,
                                      ...formHook.getValues(),
                                    })
                                      .then((x) => {
                                        SuccessToast({
                                          title: "Success",
                                          description:
                                            "Process was succesfully completed",
                                          duration: 5000,
                                          position: "top-right",
                                        });
                                      })
                                      .then((x) => {
                                        setTableLoading(false);
                                      })
                                      .catch((e) => {
                                        ErrorToast({
                                          title: "Error",
                                          description:
                                            "Process cannot be completed at this time",
                                          duration: 5000,
                                        });
                                      });
                                  });
                                }}
                                variant="primary"
                                size="sm"
                              >
                                Submit
                              </Button>
                            </Td>
                          </Tr>
                        </Fragment>
                      );
                    })}
                  </Tbody>
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>{dates[0]}</Th>
                      <Th>{dates[1]}</Th>
                      <Th>{dates[2]}</Th>
                      <Th>{dates[3]}</Th>
                      <Th>{dates[4]}</Th>
                      <Th>{dates[5]}</Th>
                      <Th></Th>
                      <Th></Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                </Table>
              </Box>

              <Pagination
                paginationProps={pagination}
                baseParam={baseParams}
                setBaseParam={setBaseParams}
              />
            </Stack>
          </Box>
        </Stack>
      </FormProvider>
    </>
  );

  return <>{component}</>;
}
