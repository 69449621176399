import agent from "api/agent";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateService from "./CRUD/CreateService";
import DeleteService from "./CRUD/DeleteService";
import UpdateService from "./CRUD/UpdateService";
import ViewService from "./CRUD/ViewService";
import {
  CreateServiceDTO,
  ServiceDTO,
  UpdateServiceDTO,
  ViewServiceDTO,
} from "./interfaces";

export interface ServicesProps {}
export default function Services(props: ServicesProps) {
  const {} = props;

  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Company Name",
      name: "companyName",
      order: 1,
    },
    {
      displayName: "Company Email",
      name: "email",
      order: 2,
    },
    {
      displayName: "Start Date",
      name: "startDate",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    ServiceDTO,
    CreateServiceDTO,
    ViewServiceDTO,
    UpdateServiceDTO
  >({
    displayProps: {
      tableName: "Services",
      caption: "Table Showing Services",
      cardTitle: "Service",
    },
    headers,
    useTableProps: {
      Method: agent.Service.Items,
    },
    crudComponents: {
      create: <CreateService />,
      delete: <DeleteService />,
      update: <UpdateService />,
      view: <ViewService />,
    },
    calls: agent.Service,
    defaultValue: {
      companyName: "",
      contactNumber: "",
      email: "",
      expectedEndDate: new Date().toISOString().split("T")[0],
      price: 0,
      startDate: new Date().toISOString().split("T")[0],
    },
  });
  return <>{component()}</>;
}
