import {
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Stack,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState, useEffect, Fragment } from "react";
import { useForm, useFormContext } from "react-hook-form";
import To from "./HTML/To";
import { InvoiceItemsDTO } from "../interfaces";
import CreateInvoice from "./CreateInvoice";
import InvoiceItem from "./CreateInvoiceItem";
import Email from "./HTML/Email";
import InvoiceDate from "./HTML/InvoiceDate";
import InvoiceDescription from "./HTML/InvoiceDescription";
import PurchaseOrder from "./HTML/PurchaseOrder";

export interface UpdateInvoiceProps {}
export default function UpdateInvoice(props: UpdateInvoiceProps) {
  const {} = props;
  const start = useRef(true);
  const type = useRef("");
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [item, setItem] = useState<InvoiceItemsDTO>();
  const [items, setItems] = useState<InvoiceItemsDTO[]>([]);
  const def = {
    name: "",
    date: new Date().toISOString().split("T")[0],
    description: "",
    amountDescription: "",
    amountTotal: 0,
    manHours: 0,
    workRate: 0,
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formHook = useForm({
    // defaultValues: useMemo(() => {
    //   return def;
    // }, [def]),
  });
  const parentFormHook = useFormContext();
  const oitems = [...parentFormHook.getValues("items")];
  console.log(oitems);
  useEffect(() => {
    setItems(oitems);
  }, [parentFormHook]);

  useEffect(() => {
    if (item !== undefined) {
      setItems([...items, item]);
    }
  }, [item]);
  useEffect(() => {
    if (start.current) {
      start.current = false;
    } else {
      parentFormHook.setValue("items", items);
    }
  }, [items]);

  function onSubmit(data: any) {
    if (type.current !== "") {
      const newItems = [...items];
      const rr = newItems.find((x) => x.name === type.current)!;
      newItems[newItems.indexOf(rr)] = data;
      setItems([...newItems]);
    } else {
      setItem(data);
    }

    onClose();
  }

  function onDelete() {
    const newItems = [...items];
    const rr = newItems.find((x) => x.name === type.current)!;
    newItems.splice(newItems.indexOf(rr), 1);
    setItems([...newItems]);
    onClose();
  }

  function onError(data: any) {
    alert("Error");
  }

  return (
    <>
      <Stack spacing={7}>
        {isDesktop ? (
          <>
            <HStack spacing={7}>
              <InvoiceDate />
              <PurchaseOrder />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing={7}>
              <InvoiceDate />
              <PurchaseOrder />
            </VStack>
          </>
        )}

        {isDesktop ? (
          <>
            <HStack spacing={7}>
              <To />
              <InvoiceDescription />
            </HStack>
          </>
        ) : (
          <>
            <VStack spacing={7}>
              <To />
              <InvoiceDescription />
            </VStack>
          </>
        )}

        <Email />
      </Stack>
      <Divider />
      <br />
      <Center>
        <Button
          onClick={() => {
            type.current = "";
            formHook.reset();
            onOpen();
          }}
          colorScheme={"whatsapp"}
        >
          Invoice Items (Click to Add)
        </Button>
      </Center>
      <Stack marginTop={5}>
        {items.map((item, index) => {
          return (
            <Fragment key={Math.random()}>
              <HStack width={"100%"}>
                <Button
                  flex={2}
                  onClick={() => {
                    onOpen();
                    type.current = item.name;
                    formHook.setValue("name", item.name);
                    formHook.setValue("date", item.date);
                    formHook.setValue("description", item.description);
                    formHook.setValue(
                      "amountDescription",
                      item.amountDescription
                    );
                    formHook.setValue("amountTotal", item.amountTotal);
                    formHook.setValue("manHours", item.manHours);
                    formHook.setValue("workRate", item.workRate);
                  }}
                  colorScheme={"teal"}
                >
                  {item.name}
                </Button>
                {/* <CloseButton /> */}
              </HStack>
            </Fragment>
          );
        })}
      </Stack>

      <Drawer size={"xl"} isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Center>Create Invoice Item</Center>
          </DrawerHeader>

          <DrawerBody>
            <br />

            <Stack spacing={10}>
              <InvoiceItem formHook={formHook} />
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={formHook.handleSubmit(onSubmit, onError)}
              colorScheme="blue"
            >
              Save
            </Button>
            {type.current !== "" && (
              <>
                <Button ml={3} onClick={() => onDelete()} colorScheme="red">
                  Delete
                </Button>
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
