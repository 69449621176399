import { Stack, HStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

export interface ViewInvoiceProps {}
export default function ViewInvoice(props: ViewInvoiceProps) {
  const {} = props;
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
       
      </Stack>
    </>
  );
}
