import { FormControl, FormLabel, Input, InputProps } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface ValueProps {
  formHook: UseFormReturn<any, any>;
  additionalInputProps?: InputProps;
}
export default function Value(props: ValueProps) {
  const {
    formHook: {
      register,
      formState: { errors },
    },
    additionalInputProps,
    
    
  } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("value", {
              required: `Value is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...additionalInputProps}
          />
        }
        label={`Value`}
        placeHolder=""
        errorMessage={errors["value"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
