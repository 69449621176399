import {
  Container,
  Stack,
  Heading,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  HStack,
} from "@chakra-ui/react";
import agent from "api/agent";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import GenericFormControl from "shared/components/form/GenericFormControl";
import { Sleep } from "shared/components/functions/generic/asyncFunctions";
import { useToast } from "shared/components/toast/useToast";
import useSecurity, { logOut } from "./useSecurity";

export interface ForgetPasswordProps {}
export default function ForgetPassword(props: ForgetPasswordProps) {
  const {} = props;
  const { ErrorToast, SuccessToast, toast, ProcessingToast } = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function submit(data: any) {
    var proId = await ProcessingToast();
    try {
      await Sleep(350);
      await agent.Security.EmailNewPassWord(data);
      toast!.close(proId);
      SuccessToast({
        description: "Email sent With Instructions",
        position: "top-right",
      }).then(() => {
        navigate("/");
      });
    } catch (error:any) {
      console.log(error);
      toast!.close(proId);
      ErrorToast({
        description: error.response.data.errorMessages[0],
        position: "top-right",
      });
    }
  }
  function error(data: any) {}
  return (
    <>
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            {/* <Logo /> */}
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>Forget Password</Heading>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg-surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <GenericFormControl
                  placeHolder=""
                  input={
                    <>
                      <Input
                        {...register("userName", {
                          required: "User Name is Required",
                        })}
                      />
                    </>
                  }
                  label="User Name"
                  errorMessage={
                    errors["userName"]?.message as string | undefined
                  }
                  isRequired={true}
                />
              </Stack>
              <Stack spacing="6">
                <Button onClick={handleSubmit(submit, error)} variant="primary">
                  Proceed
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
function ProcessingToast() {
  throw new Error("Function not implemented.");
}
