import {
  HStack,
  Avatar,
  Box,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useColorMode,
  useColorModeValue,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/react";
import AuthenticationContext from "Contexts/AuthenticationContext";
import { useContext } from "react";
import { FaUser } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import useSecurity, { logOut } from "./useSecurity";

export interface UserProfileProps {}
export default function UserProfile(props: UserProfileProps) {
  const {} = props;
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const { claims, isAuthenticated, updateClaims } = useContext(
    AuthenticationContext
  );
  const { logOut } = useSecurity({ type: "login" });
  return (
    <>
      <HStack spacing="3" ps="2">
        {/* <Menu>
          <MenuButton
            as={IconButton}
            isRound={true}
            icon={
              <Avatar
                bg={isDark ? "twitter.400" : "twitter.500"}
                name={
                  claims.find((x) => x.property === "name")!.value as string
                }
              />
            }
            ml={2}
          ></MenuButton>
          <MenuList>
            <MenuItem>
              {claims.find((x) => x.property === "name")!.value as string}
            </MenuItem>
            <MenuDivider></MenuDivider>
            <MenuItem
              onClick={() => {
                logOut(updateClaims, navigate);
              }}
            >
              Log Out
            </MenuItem>
          </MenuList>
        </Menu> */}
        <Menu>
          <MenuButton
            as={IconButton}
            isRound={true}
            icon={
              <Avatar
                bg={isDark ? "twitter.400" : "twitter.500"}
                name={
                  claims.find((x) => x.property === "name")!.value as string
                }
              />
            }
            ml={2}
          ></MenuButton>
          <MenuList
            shadow="lg"
            py="4"
            color={useColorModeValue("gray.600", "gray.200")}
            px="3"
          >
            <Text fontWeight="medium" mb="2">
              Welcome{" "}
              {claims.find((x) => x.property === "name")!.value as string}
            </Text>

            <MenuDivider />
            <MenuItem
            as={NavLink}
            to="/config"            
              rounded="md"
            >
              Configuration Settings
            </MenuItem>
            <MenuDivider />
            <MenuItem            
              onClick={() => {
                logOut(updateClaims, navigate);
              }}
              rounded="md"
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
}
