import { InputProps, Input } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface AmountTotalProps {
  additionalInputProps?: InputProps;
}
export default function AmountTotal(props: AmountTotalProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("amountTotal", {
              required: `Cost of Amount is required`,
              min: { value: 1, message: "Cost has to be greater than 1" },
              maxLength:{value:7, message:"Cost cannot be more than 9,999,999"}
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            type={"number"}
            {...additionalInputProps}
          />
        }
        label={`Amount Total`}
        placeHolder=""
        errorMessage={errors["amountTotal"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
