import { Input } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface StatusProps {}
export default function Status(props: StatusProps) {
  const {} = props;
  const {
    getValues,   
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <Input
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...{ isReadOnly: true, defaultValue: getValues()["status"] }}
          />
        }
        label={`Status`}
        placeHolder={""}
      />
    </>
  );
}
