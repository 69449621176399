import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export interface GenericFormControlProps {
  label: string;
  input: ReactElement;
  errorMessage?: string;
  placeHolder: string;
  isRequired?: boolean;
}
export default function GenericFormControl(props: GenericFormControlProps) {
  const { input, label, placeHolder, errorMessage, isRequired } = props;
  const isError = errorMessage !== undefined && errorMessage !== "";
  return (
    <>
      <FormControl isInvalid={isError} isRequired={isRequired}>
        {input}
        <FormLabel variant="floating" size="lg">
          {label}
        </FormLabel>
        {!isError ? (
          <FormHelperText>{placeHolder}</FormHelperText>
        ) : (
          <>
            <FormErrorMessage>
              <FormErrorIcon></FormErrorIcon>
              {errorMessage}
            </FormErrorMessage>
          </>
        )}
      </FormControl>
    </>
  );
}
