import { Stack } from "@chakra-ui/react";
import HourlyWage from "./HTML/HourlyWage";
import Status from "./HTML/Status";
import DateStack from "./Stacks/DateStack";
import SelectStack from "./Stacks/SelectStack";

export interface ViewContractInstanceProps {}
export default function ViewContractInstance(props: ViewContractInstanceProps) {
  const {} = props;
  return (
    <>
      <Stack spacing={7}>
        <SelectStack
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <DateStack
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <HourlyWage
          additionalInputProps={{
            isReadOnly: true,
          }}
        />
        <Status />
      </Stack>
    </>
  );
}
