import { Box, ToastProps, useColorMode } from "@chakra-ui/react";
import AppContext from "Contexts/AppContext";
import { useContext } from "react";
import { mode } from "@chakra-ui/theme-tools";

export interface IGenericToast extends ToastProps {}

export function useToast() {
  const { colorMode } = useColorMode();
  const { toast } = useContext(AppContext);

  function ErrorToast(toastProps: ToastProps) {
    const id = toast!({
      position: "top-right",
      title: "Error",
      ...toastProps,
      status: "error",
    });
    return id;
  }
  async function SuccessToast(toastProps: ToastProps) {
    toast!({
      title: "Success",
      ...toastProps,
      status: "success",
    });
  }

  async function ProcessingToast() {
    const id = toast!({
      title: "Processing",
      position: "top",
      description: <>Processing Request</>,
      status: "loading",
      duration: 100000000,
    });
    return id;
  }
  async function CustomProcessingToast(title: string, body: string) {
    const id = toast!({
      title,
      position: "top",
      description: <>{body}</>,
      status: "loading",
      duration: 100000000,
    });
    return id;
  }

  return { ErrorToast, toast, SuccessToast, ProcessingToast,CustomProcessingToast };
}
