import {
    ServiceDTO,
    CreateServiceDTO,
    ViewServiceDTO,
    UpdateServiceDTO,
  } from "components/Payroll/Service/interfaces";
  import ff, { gg } from "../general";
  const EP = process.env.REACT_APP_API_SERVICE;
  const Service = gg<
    ServiceDTO,
    CreateServiceDTO,
    ViewServiceDTO,
    UpdateServiceDTO
  >(EP!);
  
  export default Service;
  