import { Button } from "@chakra-ui/react";

export interface PaginationButtonProps {
  info: string;
  disabled?: boolean;
  onClick:()=>void
}
export default function PaginationButton(props: PaginationButtonProps) {
  const {disabled = false,info,onClick} = props;
  return (
    <>
      <Button 
      onClick={onClick}
      disabled={disabled}>{info}</Button>
    </>
  );
}
