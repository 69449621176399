import {
  ConfigurationDTO,
  CreateConfigurationDTO,
  ViewConfigurationDTO,
  UpdateConfigurationDTO,
} from "components/Payroll/Config/interfaces";
import ff, { gg, requests } from "../general";
const EP = process.env.REACT_APP_API_CONFIGURATION;
const Configuration = gg<
  ConfigurationDTO,
  CreateConfigurationDTO,
  ViewConfigurationDTO,
  UpdateConfigurationDTO
>(EP!);
Configuration.Select = () => {
  return requests.get(`${EP}/Select`, new URLSearchParams());
};
export default Configuration;
