import { Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import Address from "./HTML/Address";
import BankStack from "./HTML/Stacks/BankStack";
import IDStack from "./HTML/Stacks/IDStack";
import NameStack from "./HTML/Stacks/NameStack";

export interface DeleteEmployeeProps {}
export default function DeleteEmployee(props: DeleteEmployeeProps) {
  const formHook = useFormContext();
  return (
    <>
      <Stack spacing={7}>
        <NameStack additionalInputProps={{ isReadOnly: true}} />
      
        <IDStack additionalInputProps={{ isReadOnly: true }} />
       
      </Stack>
    </>
  );
}
