import { HStack, Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import AccountNumber from "./HTML/AccountNumber";
import Address from "./HTML/Address";
import Name from "./HTML/Name";
import NIS from "./HTML/NIS";
import BankStack from "./HTML/Stacks/BankStack";
import IDStack from "./HTML/Stacks/IDStack";
import NameStack from "./HTML/Stacks/NameStack";
import TRN from "./HTML/TRN";

export interface CreateEmployeeProps {}
export default function CreateEmployee(props: CreateEmployeeProps) {
  const {} = props;
  return (
    <>
      <Stack spacing={7}>
        <NameStack />
        {/* <Address formHook={formHook} /> */}
        <IDStack />
        {/* <BankStack /> */}
      </Stack>
    </>
  );
}
