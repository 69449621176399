import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import Main from "./components/Home/Main";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import AppContext from "Contexts/AppContext";
import { useState } from "react";
import { getClaims } from "components/Authentication/useSecurity";
import AuthenticationContext, { IClaim } from "Contexts/AuthenticationContext";

export const App = () => {
  const nClaims = getClaims();
  const [claims, setClaims] = useState<IClaim[]>(nClaims);
  const isAuthenticated = claims.length > 0;
  let isCrudAdmin = false;
  let isGameCreator = false;
 
  const { ToastContainer, toast } = createStandaloneToast();
  //const isDesktop = useBreakpointValue({ base: false, lg: true },{ssr:false});
  return (
    <>
      <ChakraProvider theme={theme}>
        <AppContext.Provider
          value={{ isLoading: false, toast, setIsLoading: () => {} }}
        >
          <AuthenticationContext.Provider
            value={{
              claims,
              isAuthenticated,
              updateClaims: setClaims,
            }}
          >
            <Main />
            <ToastContainer />
          </AuthenticationContext.Provider>
        </AppContext.Provider>
        
      </ChakraProvider>
    </>
  );
};
