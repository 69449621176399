import { Input, InputProps } from "@chakra-ui/react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface EmailProps {
  additionalInputProps?: InputProps;
}
export default function Email(props: EmailProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  
  const {   
    additionalInputProps,
  } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("email", {
              required: `Email is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...additionalInputProps}
          />
        }
        label={`Email`}
        placeHolder=""
        errorMessage={errors["email"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
