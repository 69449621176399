import {
  HStack,
  InputProps,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import CompanyName from "../CompanyName";
import ContactNumber from "../ContactNumber";
import Email from "../Email";

export interface BasicInfoStackProps {
  additionalInputProps?: InputProps;
}
export default function BasicInfoStack(props: BasicInfoStackProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { additionalInputProps } = props;
  const t = (
    <>
      <CompanyName {...additionalInputProps} />
      <Email {...additionalInputProps} />
      <ContactNumber {...additionalInputProps} />
    </>
  );
  if (isDesktop) {
    return <HStack spacing={5}>{t}</HStack>;
  }

  return (
    <>
      <VStack spacing={5}>{t}</VStack>
    </>
  );
}
