import { Input, InputProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericFormControl";

export interface ServicePriceProps {
  additionalInputProps?: InputProps;
}
export default function ServicePrice(props: ServicePriceProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { additionalInputProps } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Input
            {...register("price", {
              required: `Service Price is required`,
            })}
            id="large"
            size="lg"
            placeholder=" "
            data-peer
            {...additionalInputProps}
          />
        }
        label={`Service Price`}
        placeHolder=""
        errorMessage={errors["price"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
